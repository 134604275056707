/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import tw from "twin.macro";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { format, isBefore } from "date-fns/esm";
import { Button, DropdownField, FieldGroup } from "@clevero/components";
import { BsFillCalendar2XFill } from "react-icons/bs";

import DayComponent from "./DayComponent";
import EventList from "./EventList";
import Loader from "../Loader";

import { useGetTours } from "../../api/tours.api";
import { calcMonthList, calcYearList, monthMapping, roundCorner, takeMonth } from "../../Utility/calenderUtils";

//----------------------Styles----------------------//
const MainContainer = tw.div`m-auto p-3`;

const MenuContainer = tw.div`flex justify-between flex-col sm:flex-row my-4`;

const CalendarContainer = tw.div`relative grid grid-cols-7 auto-rows-[8.25rem] gap-px my-4 text-sm bg-gray-300 border-2 border-gray-300 rounded-xl`;

const ListContainer = tw.div`relative flex flex-col justify-center items-center gap-3 p-4 min-h-[12rem]`;

const VoucherLink = tw.span`text-LAA-primary-dim hover:(underline text-LAA-primary)`;

const StyledDropDown = props => (
    <DropdownField
        {...props}
        color={"#E0F1D7"}
        isClearable={false}
        fieldStyle={{
            background: "#E0F1D7",
            backgroundFocus: "#639A33",
            border: "transparent",
            borderFocus: "transparent",
            borderRadius: "0.5rem",
        }}
    />
);

const LoaderOverlay = tw.div`absolute h-full w-full backdrop-blur-[2px] z-50 flex justify-center items-center rounded-xl `;
//----------------------Styles----------------------//

//-------------------------------/Main Component here/-------------------------------//
function Calendar() {
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    const handleResize = e => {
        setDimensions({
            height: e.target.innerHeight,
            width: e.target.innerWidth,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    //get product url for API call//
    const [searchParams] = useSearchParams();
    // const url = process.env.NODE_ENV === "development" ? searchParams.get("url") : window.parent.location.href;
    const url = searchParams.get("url");

    //memoize today's Date//
    const currentDate = useMemo(() => new Date(), []);

    //initialize selectedDate as currentDate//
    const [selectedDate, setSelectedDate] = useState(currentDate);

    //generates a month array for currentDate/selectedDate//
    const month = takeMonth(selectedDate)();

    //get list of months and year//
    const monthList = calcMonthList(currentDate, selectedDate);
    const yearList = calcYearList(currentDate, selectedDate, 2);

    //API call to get events//
    const { data, isLoading } = useGetTours({
        params: {
            month: format(selectedDate, "MMMM"),
            year: format(selectedDate, "yyyy"),
            url,
        },
        enabled: !!selectedDate && !!url,
    });

    //month and year change handler//
    function handleChange(e, type) {
        const inputDate = e.value;

        inputDate &&
            setSelectedDate(prev => {
                let newDate;
                switch (type) {
                    case "month":
                        const prevYear = format(prev, "yyyy");
                        const month = monthMapping[inputDate.toLowerCase()];
                        newDate = new Date(`${month}/01/${prevYear}`);
                        break;
                    case "year":
                        const prevMonth = monthMapping[format(prev, "MMMM").toLowerCase()];
                        const year = inputDate;
                        newDate = new Date(`${prevMonth}/01/${year}`);
                        break;
                    default:
                        newDate = new Date().toISOString();
                }

                return isBefore(newDate, currentDate) ? currentDate : newDate;
            });
    }

    //reset date to current date if user skips to prev dates//
    useEffect(
        () => (isBefore(selectedDate, currentDate) ? setSelectedDate(currentDate) : null),
        [currentDate, selectedDate]
    );

    //change selected date to clicked date
    function onClickHandler(day) {
        if (isBefore(day, currentDate)) return null;
        setSelectedDate(day);
    }

    function toNextAvailableTour(dateString) {
        const newDate = new Date(dateString);
        setSelectedDate(newDate);
    }

    function renderMobileView() {
        let monthHasData = false;
        const componentsArray = month.map((week, wIndex) => {
            return week
                .filter(day => data && !isBefore(day, currentDate) && data[format(day, "yyyy-MM-dd")])
                .map(day => {
                    monthHasData = true;
                    return (
                        <EventList
                            renderDate={day}
                            selectedDate={selectedDate}
                            currentDate={currentDate}
                            data={data[format(day, "yyyy-MM-dd")]}
                            key={day}
                            onClick={() => onClickHandler(day)}
                        />
                    );
                });
        });
        return monthHasData ? (
            componentsArray
        ) : (
            <>
                <BsFillCalendar2XFill color="#000000a6" size="1.75rem" />
                No Activities Available
            </>
        );
    }

    return (
        <>
            <MainContainer>
                <MenuContainer>
                    <FieldGroup columns={2} tw="max-w-xs w-full">
                        <StyledDropDown
                            name="month-input"
                            value={format(selectedDate, "MMMM")}
                            options={monthList}
                            onChange={e => handleChange(e, "month")}
                        />

                        <StyledDropDown
                            name="year-input"
                            value={format(selectedDate, "yyyy")}
                            options={yearList}
                            onChange={e => handleChange(e, "year")}
                        />
                    </FieldGroup>

                    {data && data.nextTourStartDate && (
                        <Button
                            color="#FC875D"
                            variant="outline"
                            buttonStyle={{ borderWidth: 1 }}
                            onClick={() => toNextAvailableTour(data.nextTourStartDate)}
                        >
                            To next available tour
                        </Button>
                    )}
                </MenuContainer>
                <p>
                    Can't find a date that works for you?&nbsp;
                    <Link to="/gift-voucher" state={{ url }}>
                        <VoucherLink>Purchase a Gift Voucher.</VoucherLink>
                    </Link>
                </p>
                {dimensions.width >= 840 && (
                    <CalendarContainer>
                        {isLoading && (
                            <LoaderOverlay>
                                <Loader />
                            </LoaderOverlay>
                        )}

                        {month.map((week, wIndex) =>
                            week.map((day, dIndex) => {
                                return (
                                    <DayComponent
                                        renderDate={day}
                                        selectedDate={selectedDate}
                                        currentDate={currentDate}
                                        roundCorner={roundCorner(month, wIndex, dIndex)}
                                        weekIndex={wIndex}
                                        data={data && !isBefore(day, currentDate) && data[format(day, "yyyy-MM-dd")]}
                                        key={day}
                                        onClick={() => onClickHandler(day)}
                                    />
                                );
                            })
                        )}
                    </CalendarContainer>
                )}

                {dimensions.width < 840 && (
                    <ListContainer>
                        {isLoading ? (
                            <LoaderOverlay>
                                <Loader />
                            </LoaderOverlay>
                        ) : (
                            renderMobileView()
                        )}
                    </ListContainer>
                )}
            </MainContainer>
        </>
    );
}

export default Calendar;
