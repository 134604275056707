import tw from "twin.macro";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import CustomiseTable from "./CustomiseTable";
import NavigationButtons from "../../NavigationButtons";

import { addBookingUpgrades, getExtras, postPrePayment } from "../../../../api/tours.api";
import Loader from "../../../Loader";
import _ from "lodash";
const ErrComp = tw.div`my-8 text-center text-base font-semibold text-gray-500`;

const CustomiseMain = () => {
    const location = useLocation();
    const {
        booking: {
            id: bookingId,
            "total-amount-due": totalAmountDue,
            "bookings-ordered-by": orderedBy,
            autoId: bookingReference,
        },
        bookedGuests,
    } = location.state.bookingRes;
    const { sortedPrice = [] } = location.state.parsedEventDetails;
    const navigate = useNavigate();
    const navigationBtn = useRef(null);
    const toastId = useRef(null);

    const [extrasData, setExtrasData] = useState("");
    const [apiErr, setApiErr] = useState("");

    const [disableField, setDisableField] = useState(false);
    const [fields, setFields] = useState([]);
    const [fieldValues, setFieldValues] = useState({});
    const [totalAmount, setTotalAmount] = useState(0);

    function disableBack() {
        if (navigationBtn && navigationBtn.current) {
            navigationBtn.current.disableBack();
        }
    }

    function disableAllBtns() {
        if (navigationBtn && navigationBtn.current) {
            navigationBtn.current.disableNavigation();
        }
        setDisableField(true);
    }

    function enableAllBtns() {
        if (navigationBtn && navigationBtn.current) {
            navigationBtn.current.enableNavigation();
        }
        setDisableField(false);
    }

    useEffect(() => {
        disableAllBtns();

        async function getExtrasDetails(tourId) {
            const { extras, data, status } = await getExtras(tourId).catch(err => err);

            if (!extras) {
                const ApiErr = status === 404 ? data : "An error occured, please contact the administrator.";
                setApiErr(ApiErr);
                enableAllBtns();
                disableBack();
                return;
            }

            const extrasWithVariation =
                Array.isArray(extras) && extras.length
                    ? extras
                          .filter(ext => {
                              if (ext?.variation?.name.toLowerCase().includes("flexiwalk")) {
                                  return false;
                              } else {
                                  return true;
                              }
                          })
                          .filter(({ variation }) => {
                              if (!!variation) {
                                  const isNormalPriceLevelOnly =
                                      variation["normal-price-level-only"] === "true" ||
                                      variation["normal-price-level-only"] === true;

                                  const hasNormalPricingInPage1 =
                                      sortedPrice.length && sortedPrice[0]?.value === "Normal Price";
                                  return !isNormalPriceLevelOnly || hasNormalPricingInPage1;
                              } else {
                                  return false;
                              }
                          })
                    : [];

            if (extrasWithVariation.length) {
                const fields = extrasWithVariation.map(({ id, variation }) => [
                    id,
                    { guests: [], name: variation?.name, amount: 0 },
                ]);

                setFields(fields);
                setFieldValues(Object.fromEntries(fields));
                setExtrasData(extrasWithVariation);
            } else setApiErr("No extras/addons available for this tour!");

            enableAllBtns();
            disableBack();
        }

        getExtrasDetails(location.state?.eventDetails.id);
    }, [location.state, sortedPrice]);

    useEffect(() => {
        setTotalAmount(
            Object.values(fieldValues).reduce((prev, curr) => {
                return curr.amount + prev;
            }, 0)
        );
    }, [fieldValues]);

    function getPayload({ payloadTotal }) {
        const upgrades = Object.keys(fieldValues).map(key => {
            const { guests, amount, variation, name } = fieldValues[key];
            if (!guests.length) return undefined;
            return { guests, amount, id: variation.id, name, extraId: key };
        });

        return {
            upgrades: _.without(upgrades, undefined),
            bookingId,
            bookedGuests,
            totalAmount: payloadTotal,
            tourId: location.state?.eventDetails.id,
        };
    }

    function getExtraDepositForEachBookedGuest(bookedGuestId) {
        let depositAmount = 0;
        Object.keys(fieldValues).forEach(extraId => {
            const guests = fieldValues[extraId].guests;
            if (
                guests.includes("" + bookedGuestId)
                // &&
                // fieldValues[extraId].variation.name.toLowerCase().includes("flexiwalk")
            ) {
                depositAmount += +fieldValues[extraId].perGuestAmount || 0;
            }
        });
        return depositAmount;
    }

    async function onContinue() {
        disableAllBtns();

        const totalDepositRequired = location.state?.discountedGuestsArray?.reduce(
            (prev, curr) => prev + +curr["deposit-required"],
            0
        );

        const totalDiscountedAmount = Object.values(fieldValues).reduce((prev, curr) => {
            return +prev + +curr.amount;
            // curr.name.toLowerCase().includes("flexiwalk") ? +prev + +curr.amount : +prev;
        }, totalDepositRequired);

        const apiCallFlag = Object.values(fieldValues).reduce((prev, curr) => prev + curr.guests.length, 0);

        if (apiCallFlag > 0) {
            toastId.current = toast("Please wait...", {
                autoClose: false,
                closeButton: false,
                type: toast.TYPE.INFO,
            });

            const payloadTotal = Object.values(fieldValues).reduce(
                (prev, curr) => +prev + +curr.amount,
                totalAmountDue
            );

            const payload = getPayload({ payloadTotal, fieldValues });

            const { error } = await addBookingUpgrades(payload).catch(err => ({
                error: true,
            }));

            toast.dismiss(toastId.current);

            if (error) {
                toast.error("An error occurred");
                enableAllBtns();
                return disableBack();
            }
        }

        enableAllBtns();
        disableBack();

        if (+totalDiscountedAmount === 0) {
            toastId.current = toast("Please wait...", {
                autoClose: false,
                closeButton: false,
                type: toast.TYPE.INFO,
            });
            const leadBookedGuest = bookedGuests.find(guest => +guest["guest-name"][0].id === +orderedBy[0].id);
            const vouchersUsed =
                (location.state.bookingRes.booking["vouchers-used"] &&
                    location.state.bookingRes.booking["vouchers-used"].map(v => "" + v.id)) ||
                [];

            const { error } = await postPrePayment({
                bookingId,
                vouchersUsed,
                leadBookedGuest: leadBookedGuest ? ["" + leadBookedGuest.id] : [],
            }).catch(err => ({
                error: true,
            }));
            toast.dismiss(toastId.current);

            if (error) {
                toast.error("An error occurred");
                enableAllBtns();
                return disableBack();
            }

            navigate("/booking/success", {
                state: {
                    eventDetails: location.state?.eventDetails,
                    parsedEventDetails: location.state?.parsedEventDetails,
                },
            });
            return;
        }

        navigate("/booking/confirm-&-pay", {
            state: {
                bookedGuests: bookedGuests.map(bg => {
                    return {
                        ...bg,
                        "deposit-required": bg["deposit-required"] + getExtraDepositForEachBookedGuest(bg.id),
                    };
                }),
                leadBookedGuest: bookedGuests.find(guest => +guest["guest-name"][0].id === +orderedBy[0].id),
                bookingId,
                guests: location.state?.discountedGuestsArray,
                amount: totalDiscountedAmount,
                eventDetails: location.state?.eventDetails,
                parsedEventDetails: location.state?.parsedEventDetails,
                bookingReference,
            },
        });
    }

    return (
        <>
            {!extrasData && !apiErr ? (
                <Loader />
            ) : extrasData && extrasData.length ? (
                <CustomiseTable
                    extrasData={extrasData}
                    totalAmount={totalAmount}
                    fields={fields}
                    fieldValues={fieldValues}
                    setFieldValues={setFieldValues}
                    disableField={disableField}
                />
            ) : (
                <ErrComp>{apiErr}</ErrComp>
            )}

            <hr />

            <NavigationButtons ref={navigationBtn} onContinue={onContinue} />
        </>
    );
};

export default CustomiseMain;
