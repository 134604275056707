import { useQuery } from "react-query";
import { axiosInstance } from "./axiosInstance";

const defaultQueryOption = {
    retry: false,
    retryDelay: 2000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
};

export const useGetToursTable = ({ params: { url, pageIndex, pageSize }, enabled, onSuccess, onError }) => {
    return useQuery(["tour-table", url, pageIndex, pageSize], () => getToursTable({ url, pageIndex, pageSize }), {
        ...defaultQueryOption,
        keepPreviousData: true,
        onSuccess,
        onError,
        // staleTime: settings.staleCache["table-entries"],
        enabled,
    });
};

export async function getToursTable(options) {
    const url = `/tours-table-view`;
    return axiosInstance.get(url, {
        ...options,
        params: { ...options },
    });
}

export const useGetTours = ({ params: { month, year, url }, enabled, onSuccess, onError }) => {
    return useQuery(["tour-entries", month, year, url], () => getTours({ month, year, url }), {
        ...defaultQueryOption,
        onSuccess,
        onError,
        // staleTime: settings.staleCache["table-entries"],
        enabled,
    });
};

export async function getTours(options) {
    const url = `/tours-month`;
    return axiosInstance.get(url, {
        ...options,
        params: { ...options },
    });
}

export const useGetSelectData = ({ internalId, onSuccess, onError }) => {
    return useQuery(internalId, () => getSelectData(internalId), {
        ...defaultQueryOption,
        onSuccess,
        onError,
    });
};

export async function getSelectData(internalId) {
    const url = `/select/${internalId}`;
    return axiosInstance.get(url, {});
}

export async function checkExistingGuest(payload) {
    const url = `/existingGuest`;
    return axiosInstance.post(url, payload);
}

export async function validateJoinCode(bookingId) {
    const url = `/applyJoinCode`;
    return axiosInstance.get(url, { params: { bookingId } });
}

export async function createBooking(payload) {
    const url = `/create/booking`;
    return axiosInstance.post(url, payload);
}

export async function applyCouponCode(payload) {
    const url = `/applyCouponCode`;
    return axiosInstance.post(url, payload);
}

export const useGetExtras = ({ tourId, enabled, onSuccess, onError }) => {
    return useQuery([tourId], () => getExtras(tourId), {
        ...defaultQueryOption,
        onSuccess,
        onError,
        enabled,
    });
};

export async function getExtras(tourId) {
    const url = `/extras`;
    return axiosInstance.get(url, { params: { tourId } });
}

export async function updateExtras(payload) {
    const url = `/extras`;
    return axiosInstance.put(url, payload);
}

export async function addBookingUpgrades(payload) {
    const url = `/booking-upgrades/extras`;
    return axiosInstance.post(url, payload);
}

export async function postPayment(payload) {
    const url = `/payment`;
    return axiosInstance.post(url, payload);
}
export async function postPrePayment(payload) {
    const url = `/pre-payment`;
    return axiosInstance.post(url, payload);
}

export const getProductByUrl = id => axiosInstance.get(`/product-url?url=${id}`);

export const createGiftVoucher = data => axiosInstance.post(`/gift-voucher`, data);

export const createGuest = data => axiosInstance.post(`/create/guest`, data);
